










































































































































































































































import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import SeletorDeProdutos from '@/components/produto/SeletorDeProdutos.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { ItemDaPreVenda, PreVenda } from '@/models/pre-venda/PreVenda'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { converterProdutoParaProdutoSimples, obterQuantidadePesadaNaBalanca } from '@/shareds/produto-shareds'
import { maiorOuIgualAZero, obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindConfiguracaoDaContaUseCase, FindLojaUseCase, FindProdutoUseCase } from '@/usecases'
import { ItemPreVendaUseCase } from '@/usecases/venda/ItemPreVendaUseCase'
import { PreVendaUseCase } from '@/usecases/venda/PreVendaUseCase'
import axios, { CancelTokenSource } from 'axios'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import VueQuagga from "vue-quaggajs"
import { DataOptions } from 'vuetify'
import DialogoDeCsvItemDaPreVenda from './DialogoDeCsvItemDaPreVenda.vue'

Vue.use(VueQuagga);

@Component({
	components: {
		SeletorDeProdutos,
		Confirmacao,
		SeletorDeLojasDoUsuario,
		DialogoDeCsvItemDaPreVenda,
	},
})
export default class DialogoDeCadastroDaPreVenda extends Vue {
	@Ref() dialogoCsv!: DialogoDeCsvItemDaPreVenda

	pesquisa = ''
	mostra = false
	carregando = false
	showCamera = false
	totalRegistros = -1
	buscandoProduto = false
	salvandoPreVenda = false
	editandoPreVenda = false
	lojaSelecionada: string | null = null
	carregandoItemNaPreVenda = false
	preVenda: PreVenda = criarPreVenda()
	itensDaPreVenda: ItemDaPreVenda[] = []
	itemDePreVenda: ItemDaPreVenda = criarItemPreVenda()
	valorLido: string | null = null
	cancelando: string[] = []
	cancelToken: CancelTokenSource | null = null
	mostrarDialogoObservacao = false
	mostrarDialogoSelecaoProduto = false
	mostrarDialogoLeituraDeCodigoBarras = false

	preVendaUseCase = new PreVendaUseCase()
	itemPreVendaUseCase = new ItemPreVendaUseCase()
	findProdutoUseCase = new FindProdutoUseCase()
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()
	findLojaUseCase = new FindLojaUseCase()

	headers = [
		{ text: 'Produto', value: 'produto.nome' },
		{ text: 'Sku', value: 'produto.sku' },
		{ text: 'Quantidade', value: 'quantidade' },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	readerSize = {
		width: 860,
		height: 480,
	}

	paginacao: DataOptions = {
		page: 1,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	async mostrar(preVendaSelecionada?: PreVenda, editando?: boolean) {
		this.preVenda = preVendaSelecionada || criarPreVenda()
		this.editandoPreVenda = editando || false
		if(this.preVenda.id) {
			this.lojaSelecionada = this.preVenda.loja?.id || null
			this.buscarItensDaPreVenda()
		} else {
			this.salvarPreVenda()
		}
		this.mostra = true
	}

	concluir() {
		this.mostra = false
		this.carregando = false
		this.totalRegistros = -1
		this.itensDaPreVenda = []
		this.lojaSelecionada = null
		this.buscandoProduto = false
		this.preVenda = criarPreVenda()
		this.mostrarDialogoObservacao = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoLeituraDeCodigoBarras = false
		this.paginacao = {
			page: 1,
			itemsPerPage: 5,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}
		this.$emit('buscarPreVendas')
	}

	async cancelar() {
		this.lojaSelecionada = null
		this.mostra = false
		this.carregando = false
		this.totalRegistros = -1
		this.itensDaPreVenda = []
		this.buscandoProduto = false
		this.mostrarDialogoObservacao = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoLeituraDeCodigoBarras = false
		this.paginacao = {
			page: 1,
			itemsPerPage: 5,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}

		try {
			if(this.preVenda.id && !this.editandoPreVenda) {
				await this.preVendaUseCase.remover(this.preVenda.id)
			}
		} catch(e) {
			AlertModule.setError(e)
		}		
	}

	@Watch('paginacao')
	async buscarItensDaPreVenda() {
		if(!this.preVenda.id) return
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const params = {
				idPreVenda: this.preVenda.id || null,
				pesquisa: this.pesquisa,
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				sort: 'createdAt,desc',
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const listaDeItensDaVenda = await this.itemPreVendaUseCase.findItensDaPreVenda(params, axiosConfig)
			this.itensDaPreVenda = listaDeItensDaVenda.content
			this.totalRegistros = listaDeItensDaVenda.totalElements
			this.carregando = false
		} catch(e) {
			this.carregando = false
			AlertModule.setError(e)
		}
	}

	@Watch("lojaSelecionada")
	async salvarPreVenda() {
		if(!this.lojaSelecionada) return

		this.salvandoPreVenda = true

		try {
			this.preVenda.loja = await this.findLojaUseCase.findLojaById(this.lojaSelecionada)
			const preVendaCriada = await this.preVendaUseCase.salvar(this.preVenda)
			this.preVenda = preVendaCriada
		} catch(e) {
			AlertModule.setError(e)
		} finally {
			this.salvandoPreVenda = false
		}
	}

	async atualizarListaDeProdutos(item: ItemDaPreVenda) {
		this.carregandoItemNaPreVenda = true
		this.preVenda.itens = this.preVenda.itens ?? [];
		const existeItemNaLista = this.itensDaPreVenda.findIndex(itemLista =>
			itemLista.produto.id === item.produto.id,
		);

		try {
			if (this.totalRegistros >= 500 && existeItemNaLista === -1) {
				throw new Error('A solicitação excede o limite de 500 produtos')
			}
			
			await this.itemPreVendaUseCase.salvarOuAtualizarItemPreVenda(item, this.preVenda.id)
			this.mostrarDialogoSelecaoProduto = false
			this.carregandoItemNaPreVenda = false
		} catch (error: any) {
			AlertModule.setError(error);
			this.mostrarDialogoSelecaoProduto = false
			this.carregandoItemNaPreVenda = false
		} finally {
			this.buscarItensDaPreVenda();
		}
	}


	async removerItemDaPreVenda(idItem: string) {
		if(!idItem) return
		this.cancelando.push(idItem)
		try {
			await this.itemPreVendaUseCase.remover(idItem)
			this.cancelando = this.cancelando.filter(id => idItem !== id)
			this.buscarItensDaPreVenda()
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoPorIdentificador(this.valorLido)

				const configuracaoDeConta = await this.findConfiguracaoDaContaUseCase.find()

				const quantidadeDeItens =
					produto.unidadeDeMedida !== 'KG' && produto.unidadeDeMedida != 'MT'
						? 1
						: obterQuantidadePesadaNaBalanca(
							'',
							configuracaoDeConta?.medida || 6,
							configuracaoDeConta?.eans || 7,
							configuracaoDeConta?.casasNumericas || 4,
						)

				const item: ItemDaPreVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: quantidadeDeItens,
					preco: produto.preco || 0,
					chaveNotaOrigem: '',
				}

				this.atualizarListaDeProdutos(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}

	get usuario() {
		return UserLoginStore.usuario
	}
}

function criarPreVenda(): PreVenda {
	return {
		id: '',
		identificador: '',
		situacao: 'Aberto',
		dataHoraCriacao: null,
		itens: [],
		loja: null,
		cpfOuCnpjDoCliente: '',
		vendedor: null,
		tipoDePreVenda: 'venda',
	}
}

function criarItemPreVenda(): ItemDaPreVenda {
	return {
		id: '',
		produto: {	
			id: '',
			nome: '',
			tipo: 'Padrão',
			preco: 0,
			pesoLiquido: 0,
			pesoBruto: 0,
			descontoVarejo: 0,
			eans: [],
			sku: null,
			identificadores:[],
			nomeCompleto: '',
			produtoDesativado: false,
			isValePresente: false,
			urlImagens: null,
			skuPai: null,
		},
		quantidade: 0,
		preco: 0,
		chaveNotaOrigem: '',
	}
}
